<template>
    <div>
        <div class="row">
          <div class="col-xl-12 d-flex justify-content-end gutter-b">
            <span class="d-flex align-items-center mr-5">
              {{ $t("goodwemonitoring.title.last_sync") }} : <span class="font-weight-bolder ml-2 text-success">{{ plant.last_update | moment("D.M.YYYY HH:mm:ss") }}</span>
            </span>
            <span class="btn btn-success" @click.prevent="resyncDevice" ref="resync_btn">
              <span class="svg-icon svg-icon-md svg-icon-white">
                <inline-svg src="/media/svg/icons/General/Update.svg" />
              </span>
              {{ $t("goodwemonitoring.button.sync") }} 
            </span>
              <router-link
                :to="{ name: 'goodwemonitoring_plants'}"
                class="btn btn-outline-primary btn-xxl ml-2"
                >
                {{ $t("button.back") }}
              </router-link>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-7">
            <!--begin::Card-->
            <div class="card card-custom gutter-b card-stretch">
              <!--begin::Body-->
              <div class="card-body">
                <!--begin::Section-->
                <div class="d-flex align-items-center">
                  <!--begin::Pic-->
                  <div class="flex-shrink-0 mr-4 symbol symbol-50 symbol-circle">
                    <img src="../../assets/images/goodwe_logo.svg" alt="image">
                  </div>
                  <!--end::Pic-->
                  <!--begin::Info-->
                  <div class="d-flex flex-column mr-auto">
                    <!--begin: Title-->
                    <span class="card-title font-weight-bolder font-size-h5 text-dark mb-1">{{ plant.plant_name }}</span>
                    <span class="text-muted font-weight-bold">{{ plant.plant_id }}</span>
                    <!--end::Title-->
                  </div>
                  <!--end::Info-->
                </div>
                <!--end::Section-->
                <!--begin::Content-->
                <div class="d-flex flex-wrap mt-14">
                  <div class="mr-12 d-flex flex-column mb-7">
                    <span class="d-block font-weight-bold mb-4">{{ $t("goodwemonitoring.plants.label.status") }}</span>

                    <span v-if="plant.status == 1" class="btn btn-success btn-sm font-weight-bold btn-upper btn-text">{{ $t("goodwemonitoring.label.generating") }}</span>
                    <span v-if="plant.status == -1" class="btn btn-danger btn-sm font-weight-bold btn-upper btn-text">{{ $t("goodwemonitoring.label.offline") }}</span>
                    <span v-if="plant.status == 0" class="btn btn-warning btn-sm font-weight-bold btn-upper btn-text">{{ $t("goodwemonitoring.label.waiting") }}</span>
                    <span v-if="plant.status == 2" class="btn btn-danger btn-sm font-weight-bold btn-upper btn-text">{{ $t("goodwemonitoring.label.fault") }}</span>
                                    
                  </div>
                  <div class="mr-12 d-flex flex-column mb-7">
                    <span class="d-block font-weight-bold mb-4">{{ $t("goodwemonitoring.plants.label.created_date") }}</span>
                    <span class="btn btn-secondary btn-sm font-weight-bold btn-upper btn-text">{{ plant.create_time | moment("D.M.YYYY HH:mm:ss") }}</span>
                  </div>
                </div>
                <!--end::Content-->
                <!--begin::Text-->
                <div class="font-size-h4 mt-5 mb-11">
                    <span class="text-muted font-weight-bold">
                      <span v-if="plant.plant_type == 1"><small class="fas fa-battery-full text-success pr-1"></small> {{ $t("goodwemonitoring.plants.label.battery_storage") }}</span>
                      <span v-if="plant.plant_type == 2"><small class="fas fa-house-user text-success pr-1"></small> {{ $t("goodwemonitoring.plants.label.residental") }}</span>
                      <span v-if="plant.plant_type == 3"><small class="fab fa-houzz text-success pr-1"></small> {{ $t("goodwemonitoring.plants.label.commercial_rooftop") }}</span>
                      <span v-if="plant.plant_type == 4"><small class="fas fa-equals text-success pr-1"></small> {{ $t("goodwemonitoring.plants.label.ground_mounted") }}</span>
                  </span>
                </div>
                <!--end::Text-->
                <!--begin::Blog-->
                <div class="d-flex flex-wrap">
                  <!--begin: Item-->
                  <div class="mr-20 d-flex flex-column mb-7">
                    <span class="font-weight-bolder mb-4">{{ $t("goodwemonitoring.plants.label.total_generated") }}</span>
                    <span class="font-weight-bolder font-size-h5 pt-1">
                      {{ plant.total }} <span class="font-weight-bold text-dark-50">kW</span>
                    </span> 
                  </div>
                  <!--end::Item-->
                </div>
                <!--end::Blog-->
              </div>
              <!--end::Body-->
            </div>
            <!--end::Card-->
          </div>

          <div class="col-xl-5">
            <!--begin::Card-->
            <div class="card card-custom gutter-b card-stretch">

                <!--begin::Body-->
                <div class="card-body p-0">
                  <div
                    class="d-flex align-items-center justify-content-between card-spacer flex-grow-1"
                  >

                    <div class="d-flex flex-column text-left">
                      <span class="text-dark-75 font-weight-bolder font-size-h4">Denní graf výroby místa</span>
                      <span class="text-muted font-weight-bold mt-2">Členěno podle hodin</span>
                    </div>
                  </div>
                  <!--begin::Chart-->
                  <apexchart
                    class="card-rounded-bottom"
                    :options="chartOptions"
                    :series="series"
                    type="area"
                    width="100%"
                  ></apexchart>
                  <!--end::Chart-->
                </div>
                <!--end::Body-->

            </div>
          </div>
				</div>
        
        <div class="row">
           <div class="col-xl-12">
               <div class="card card-custom gutter-b">
                  <div class="card-header border-0 pt-5">
                    <h3 class="card-title align-items-start flex-column"><span class="card-label font-weight-bolder text-dark">{{ $t("goodwemonitoring.grids.title.heading") }}</span><span class="text-muted mt-3 font-weight-bold font-size-sm">Celkem nalezeno: <strong>{{ plant.grid.length }} zařízení</strong></span></h3>
                  </div>
                  <div class="card-body">
                    <div class="table-responsive" v-if="plant.grid.length > 0">
                      <table class="table table-head-custom table-head-bg table-vertical-center">
                        <thead>
                          <tr class="text-left text-uppercase">
                            <th width="30"></th>
                            <th style="min-width: 150px">{{ $t("label.name") }}</th>
                            <th width="80" class="text-center">{{ $t("goodwemonitoring.grids.label.capacity") }}</th>
                            <th class="text-center">{{ $t("goodwemonitoring.grids.label.total_generation") }}</th>
                            <th style="min-width: 100px" class="text-center">{{ $t("goodwemonitoring.grids.label.status") }}</th>
                            <th width="100">{{ $t("goodwemonitoring.grids.label.arm_version") }}</th>
                          </tr>
                        </thead>
                        <tbody v-if="!loading">
                          <tr v-for="(grid, index) in plant.grid" :key="index">

                              <td class="pl-0">
                                <router-link
                                  :to="{ name: 'goodwemonitoring_grids_detail', params: { id: grid.invertersn }}"
                                  class="btn btn-icon btn-link btn-light-primary btn-hover-primary btn-xs"
                                  >
                                  <span class="svg-icon svg-icon-xs svg-icon-primary">
                                    <inline-svg src="/media/svg/icons/General/Search.svg" />
                                  </span>
                                </router-link>
                              </td>

                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="symbol symbol-50 symbol-light mr-4">           
                                    <small class="fas fa-border-all text-success pr-1"></small> 
                                  </div>
                                  <div>
                                    <span class="text-dark-75 font-weight-bolder mb-1" v-if="grid.model_type">{{ grid.model_type }}</span>
                                    <span v-else class="text-muted text-dark-75 font-weight-bolder mb-1">-</span>
                                    <span class="text-muted d-block">{{ $t("goodwemonitoring.label.sn") }}: <span class="font-weight-bolder">{{ grid.invertersn }}</span></span>
                                  </div>
                                </div>
                              </td>
                              <td class="text-center">
                                <span v-if="grid.capacity">{{ grid.capacity }} <span class="text-muted">kW</span></span>
                                <span v-else>-</span>
                              </td>
                              <td class="text-center">
                                <span v-if="grid.total_generation">{{ grid.total_generation }} <span class="text-muted">kW</span></span>
                                <span v-else>-</span>
                              </td>
                              <td class="text-center">
                                <b-badge variant="success" v-if="grid.status == 1">{{ $t("goodwemonitoring.label.generating") }}</b-badge>
                                <b-badge variant="danger" v-if="grid.status == -1">{{ $t("goodwemonitoring.label.offline") }}</b-badge>
                                <b-badge variant="warning" v-if="grid.status == 0">{{ $t("goodwemonitoring.label.waiting") }}</b-badge>
                                <b-badge variant="danger" v-if="grid.status == 2">{{ $t("goodwemonitoring.label.fault") }}</b-badge>
                              </td>
                              <td class="pr-0 text-center">
                                <span v-if="grid.arm_version">{{ grid.arm_version }}</span>
                                <span v-else>-</span>
                              </td>
                            </tr>        
                        </tbody>
                      </table>
                    </div>
                    <div v-else class="py-10 text-center text-muted">
                      {{ $t("goodwemonitoring.grids.title.none_found") }}
                    </div>
                  </div>
              </div>
           </div>
        </div>  

        <div class="row">
           <div class="col-xl-12">
               <div class="card card-custom gutter-b">
                  <div class="card-header border-0 pt-5">
                    <h3 class="card-title align-items-start flex-column"><span class="card-label font-weight-bolder text-dark">{{ $t("goodwemonitoring.batteries.title.heading") }}</span><span class="text-muted mt-3 font-weight-bold font-size-sm">Celkem nalezeno: <strong>{{ plant.battery.length }} zařízení</strong></span></h3>
                  </div>
                  <div class="card-body">
                    <div class="table-responsive" v-if="plant.battery.length > 0">
                      <table class="table table-head-custom table-head-bg table-vertical-center">
                        <thead>
                          <tr class="text-left text-uppercase">
                            <th width="30"></th>
                            <th style="min-width: 150px">{{ $t("label.name") }}</th>
                            <th width="80">{{ $t("goodwemonitoring.batteries.label.capacity") }}</th>
                            <th class="text-center">{{ $t("goodwemonitoring.batteries.label.total_generation") }}</th>
                            <th style="min-width: 100px" class="text-center">{{ $t("goodwemonitoring.batteries.label.status") }}</th>
                            <th width="100">{{ $t("goodwemonitoring.batteries.label.firmware") }}</th>
                          </tr>
                        </thead>
                        <tbody v-if="!loading">
                          <tr v-for="(battery, index) in plant.battery" :key="index">
                              <td class="pl-0">
                                <router-link
                                  :to="{ name: 'goodwemonitoring_batteries_detail', params: { id: battery.invertersn }}"
                                  class="btn btn-icon btn-link btn-light-primary btn-hover-primary btn-xs"
                                  >
                                  <span class="svg-icon svg-icon-xs svg-icon-primary">
                                    <inline-svg src="/media/svg/icons/General/Search.svg" />
                                  </span>
                                </router-link>
                              </td>

                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="symbol symbol-60 symbol-light mr-4">           
                                    <small class="fas fa-battery-full text-success pr-1"></small> 
                                  </div>
                                  <div>
                                    <span class="d-block"><span class="font-weight-bolder">{{ battery.invertersn }}</span></span>
                                  </div>
                                </div>
                              </td>
                              <td class="text-center">
                                <span v-if="battery.capacity">{{ battery.capacity }} <span class="text-muted">kW</span></span>
                                <span v-else>-</span>
                              </td>
                              <td class="text-center">
                                <span v-if="battery.total_generation">{{ battery.total_generation }} <span class="text-muted">kW</span></span>
                                <span v-else>-</span>
                              </td>
                    
                              <td class="text-center">
                                <b-badge variant="success" v-if="battery.status == 1">{{ $t("goodwemonitoring.label.generating") }}</b-badge>
                                <b-badge variant="danger" v-if="battery.status == -1">{{ $t("goodwemonitoring.label.offline") }}</b-badge>
                                <b-badge variant="warning" v-if="battery.status == 0">{{ $t("goodwemonitoring.label.waiting") }}</b-badge>
                                <b-badge variant="danger" v-if="battery.status == 2">{{ $t("goodwemonitoring.label.fault") }}</b-badge>
                              </td>
                              <td class="pr-0 text-center">
                                <span v-if="battery.firmware_version">{{ battery.firmware_version }}</span>
                                <span v-else>-</span>
                              </td>
                            </tr>        
                        </tbody>
                      </table>
                    </div>
                     <div v-else class="py-10 text-center text-muted">
                      {{ $t("goodwemonitoring.batteries.title.none_found") }}
                    </div>
                  </div>
              </div>
           </div>
        </div> 

         <div class="row">
          <div class="col-xl-12">
            <!--begin::Card-->
            <div class="card card-custom gutter-b card-stretch">
              <!--begin::Body-->
              <div class="card-body">
                <!--begin::Section-->
                <div class="d-flex align-items-center">
                  <!--begin::Pic-->
                  <div class="flex-shrink-0 mr-4 symbol symbol-50 symbol-circle">
                    <span class="svg-icon svg-icon-success svg-icon-xxl">
                     <inline-svg class="svg-icon" src="/media/svg/icons/Code/Commit.svg" />
                    </span>
                  </div>
                  <!--end::Pic-->
                  <!--begin::Info-->
                  <div class="d-flex flex-column mr-auto">
                    <!--begin: Title-->
                    <span class="card-title font-weight-bolder font-size-h5 text-dark mb-1">{{ $t("goodwemonitoring.plants.title.log_history") }}</span>
                    <!--end::Title-->
                  </div>
                  <!--end::Info-->
                </div>
                <!--end::Section-->

                  <perfect-scrollbar
                    class="navi navi-hover scroll mt-4"
                    style="max-height: 500px; position: relative;"
                    v-if="plant_log.length > 0"
                  >
                    <table class="table table-head-custom table-head-bg table-vertical-center" >
                      <thead>
                        <tr class="text-left text-uppercase">
                          <th >{{ $t("goodwemonitoring.plants.label.pac") }}</th>
                          <th class="text-center">{{ $t("goodwemonitoring.plants.label.eday") }}</th>
                          <th class="text-center">{{ $t("goodwemonitoring.plants.label.total") }}</th>
                          <th class="text-center">{{ $t("goodwemonitoring.plants.label.capacity") }}</th>
                          <th class="text-right">{{ $t("goodwemonitoring.plants.label.last_update") }}</th>
                        </tr>
                      </thead>
                      <tbody v-if="!loading">
                        <tr v-for="(log, index) in plant_log" :key="index">
                            <td align="left">
                              {{log.pac}}
                            </td>
                            <td align="center">
                              {{log.eday}}
                            </td>
                            <td align="center">
                              {{log.total}}
                            </td>
                              <td align="center">
                              {{log.capacity}}
                            </td>
                            <td align="right">
                              {{log.last_update | moment("D.M.YYYY HH:mm:ss")}}
                            </td>
                        </tr>
                      </tbody>
                    </table>
                  </perfect-scrollbar>
                  <p v-else>{{ $t("goodwemonitoring.plants.title.no_history_found") }}</p>

              </div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  
  components: {

  },
  data() {
    return {
      plant: null,
      plant_log: null,
      chartOptions: {},
      series: [
        {
          name: "Net Profit",
          data: [40, 40, 30, 30, 35, 35, 50]
        }
      ]
    };
  },
    metaInfo () { 
      return { title: this.$t("goodwemonitoring.meta.plant_detail")} 
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("goodwemonitoring.meta.plants"), subtitle: this.$t("goodwemonitoring.meta.plant_detail") }]);
    this.getPlantDetail();
    this.getPlantLog();

    this.chartOptions = {
      chart: {
        type: "area",
        height: 150,
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        },
        sparkline: {
          enabled: true
        }
      },
      plotOptions: {},
      legend: {
        show: false
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        type: "solid",
        opacity: 1
      },
      stroke: {
        curve: "smooth",
        show: true,
        width: 3,
        colors: [this.layoutConfig("colors.theme.base.success")]
      },
      xaxis: {
        categories: ["Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug"],
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        labels: {
          show: false,
          style: {
            colors: this.layoutConfig("colors.gray.gray-500"),
            fontSize: "12px",
            fontFamily: this.layoutConfig("font-family")
          }
        },
        crosshairs: {
          show: false,
          position: "front",
          stroke: {
            color: this.layoutConfig("colors.gray.gray-300"),
            width: 1,
            dashArray: 3
          }
        },
        tooltip: {
          enabled: false
        }
      },
      yaxis: {
        min: 0,
        max: 55,
        show: false,
        labels: {
          show: false,
          style: {
            colors: this.layoutConfig("colors.gray.gray-500"),
            fontSize: "12px",
            fontFamily: this.layoutConfig("font-family")
          }
        }
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0
          }
        },
        hover: {
          filter: {
            type: "none",
            value: 0
          }
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0
          }
        }
      },
      tooltip: {
        style: {
          fontSize: "12px",
          fontFamily: this.layoutConfig("font-family")
        },
        y: {
          formatter: function(val) {
            return "$" + val + " thousands";
          }
        }
      },
      colors: [this.layoutConfig("colors.theme.light.success")],
      markers: {
        colors: [this.layoutConfig("colors.theme.light.success")],
        strokeColor: [this.layoutConfig("colors.theme.base.success")],
        strokeWidth: 3
      },
      grid: {
        show: false,
        padding: {
          left: 0,
          right: 0
        }
      }
    };
  },
  methods: {
    getPlantDetail() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet("/goodwe-monitoring/plants/" + this.$route.params.id)
        .then((response) => {
          this.plant = response.data.data;
          this.loading = false;
        });
    },
    getPlantLog() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet("/goodwe-monitoring/plants/" + this.$route.params.id + "/data-logs?count=20")
        .then((response) => {
          this.plant_log = response.data.data;
          this.loading = false;
        });
    },
    resyncDevice() {
        // Loading spinner
      const submitButton = this.$refs["resync_btn"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.loading = true;

      ApiService.apiGet("/goodwe-monitoring/plants/" + this.plant.plant_id + "/resync")
        .then(() => {
            this.loading = false;
            submitButton.classList.remove("spinner","spinner-light","spinner-right");
            this.getPlantDetail();
        });
    }
  }
};
</script>
